
import { defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const route = useRoute();
    const slug: any = route.params.slug;
    const packageId = slug.split("-")[0];
    const paymentId = slug.split("-")[1];
    const promoId = slug.split("-")[2];

    const dataInv = reactive({
      data: {
        invoice: "210419BNCPHS",
        createdAt: "2021-04-20 00:12:40",
        billingTo: {
          name: "Test Member",
          email: "test@adhigama.education",
          phone: "6285340800358",
        },
        paymentTo: {
          name: "Adhigama Education",
          address: "Gedung Megah Group - Jl. Adhyaksa Baru No.49",
          phone: "0811 460 0098",
        },
        items: [
          {
            paket: "Paket Platinum",
            price: 994000,
            discount: "50%",
            subtotal: 497000,
          },
        ],
        paymentInfo: {
          paymentType: "Virtual Account",
          bankAccount: "Adhigama Education/Test Member",
          bankName: "Bank Permata",
          bankNumber: "(013)-7178329835371324",
        },
      },
    });

    const getInvoice = async () => {
      cash("#loadingInvoice").show();
      cash("#dataInvoice").hide();
      cash("#mungkinError").hide();
      const hide = () => {
        cash("#loadingInvoice").hide();
      };

      const body = new URLSearchParams();
      body.append("packageId", packageId);
      body.append("paymentId", paymentId);
      body.append("promoId", promoId === "0" ? "" : promoId);

      const url = api.memberOrderPaket;
      const hitApi = await api.hitApiPost(url, body, hide);
      console.log("hitApi ", hitApi);

      if (hitApi.status !== 200) {
        cash("#mungkinError").show();
      } else {
        cash("#dataInvoice").show();
        const rs = hitApi.data.response;
        dataInv.data = rs;
      }
    };
    getInvoice();

    return {
      dataInv,
    };
  },
});
